import * as React from "react"
import { graphql, PageProps } from "gatsby"

import SEO from "../components/seo"
import PublicationIndex from "../templates/publication-list"



export default function Component ({
                                     data,
                                     location,
                                     pageContext
                                   }) {
  return (
    <main>
      <h1>Welcome.</h1>
      <p>I'm a Senior Research Scientist at Comcast. I’ve received my Ph.D. from the Department of
        Computer Science and Software Engineering at Auburn University. My research focuses on artificial intelligence
        and natural language processing.</p>

      {/*<h2>News</h2>**/}
      <h2>Recent Publications</h2>
      <PublicationIndex data={data} location={location} pageContext={pageContext}></PublicationIndex>
      <SEO title="Homepage" />
    </main>
  )
}


export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
     allReference (
      sort: { fields: [date, pages, title], order: [DESC, ASC, ASC] }
      limit: 3
      skip: 0
    ) {
      edges {
        node {
          key
          title
          authors
          date 
          booktitle
          volume
          number
          pages
          month
          year
          image  {
            extension
            publicURL
          }
          fields {
            cleaned
            citations
            file_citations
          }
          file {
            publicURL
          }
          post {
            publicURL
          } 
          post_link
          code_link
          short
          acceptance
        }
      }
    }
  }
`

